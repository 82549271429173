import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Narrow from "../components/narrow"

const StyledAboutPage = styled.div`
  padding: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  .about-text {
    h1 {
      margin-top: 0;
    }
    h2 {
      margin-top: 0;
    }
    h3 {
      margin-top: 0;
    }
    p {
      margin-top: 0;
    }
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 1rem;
  }
`

const AboutPage = () => {
  const query = useStaticQuery(graphql`
    query {
      contentfulGeneral {
        aboutPageText {
          childMarkdownRemark {
            html
          }
        }
        aboutPageImage {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Helmet>
        <title>Agnes Suto | Um</title>
      </Helmet>
      <Narrow>
        <StyledAboutPage>
          <Img fluid={query.contentfulGeneral.aboutPageImage.fluid} />
          <div
            className="about-text"
            dangerouslySetInnerHTML={{
              __html:
                query.contentfulGeneral.aboutPageText.childMarkdownRemark.html,
            }}
          ></div>
        </StyledAboutPage>
      </Narrow>
    </Layout>
  )
}

export default AboutPage
